import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const FormCrsPage = () => {
  const pdfUrl = `/Form-CRS.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Form CRS (ADV Part 3)" />
      <Container>
        <h2 className="text-center font-size-base">
          Ant Money Advisors, LLC. Form CRS Relationship Summary
        </h2>
        <p>
          There are different ways you can get help with your investments. You
          should carefully consider which types of accounts and services are
          right for you. Free and simple tools are available to research firms
          and financial professionals at{" "}
          <a className="font-italic" href="https://www.investor.gov/CRS">
            Investor.gov/CRS
          </a>
          , which also provides educational materials about broker-dealers,
          investment advisers, and investing.
        </p>
        <p>
          We sponsor the Ant Money Investment Program that includes both
          investment advisory services offered by us and brokerage services
          offered by a separate broker-dealer firm. Investment advisory and
          brokerage services and fees differ and it is important for you to
          understand the differences.
        </p>
        <p>
          We are an investment adviser registered with the Securities and
          Exchange Commission and provide only the advisory services in the
          program, including selection and automated management of investments.
          We work with an unaffiliated broker-dealer, which provides brokerage
          accounts and services in the program. The program is called a “wrap
          fee program,” which means that all the investment advisory and most of
          the brokerage services included in the program are “wrapped” together
          and covered by a single fee. This document gives you a summary of the
          types of services we provide and how you pay. Please ask us for more
          information. There are some suggested questions to ask us at the end
          of each section.
        </p>
        <h2 className="text-center text-underline font-size-base">
          RELATIONSHIPS AND SERVICES
        </h2>
        <h3 className="font-size-base font-italic">
          WHAT INVESTMENT SERVICES AND ADVICE CAN YOU PROVIDE TO ME?
        </h3>
        <p>
          We offer investment advisory services to retail investors including
          automatically managing clients’ portfolios and a wrap fee program,
          which includes selecting core investments for our clients’ accounts,
          letting clients pick certain other investments for their account if
          they want to.
        </p>
        <ul>
          <li>
            We offer services that are discretionary, which means we decide
            which investments to buy or sell for your account. We exercise
            discretion by choosing exchange-traded funds (ETFs) for your
            portfolio. Our discretion is limited by the terms of our investment
            advisory agreement.
          </li>
          <li>
            We also offer non-discretionary advisory services, which means you
            make the ultimate decision whether to add to your portfolio certain
            stocks or similar investments from a list we provide.
          </li>
          <li>
            When you choose investment, you decide when to place your order. We
            decide how many investments you may choose to add, how much of your
            account to invest in each investment you choose, and when during the
            trading day to buy or sell.
          </li>
          <li>
            We will automatically rebalance investments in your portfolio when
            you deposit or withdraw money, when you buy or sell investments, and
            periodically at other times we choose. Rebalancing may include
            automatically selling some holdings of an investment you picked if
            we believe your account has become too concentrated in that
            investment.
          </li>
          <li>
            We will regularly monitor your account and review your portfolio no
            less than quarterly. Our monitoring will be limited to ensuring our
            software-based algorithm continues to buy, rebalance, and sell ETFs
            and Eligible Stocks according to the target allocations of your Core
            Portfolio and Explore Portfolio.
          </li>
          <li>
            Our investment advice covers a limited selection of investments.
            Other firms could provide advice on a wider range of choices, some
            of which might have lower costs or risks.
          </li>
          <li>
            There is an account minimum of $5 to open a new account, which we
            may waive at our discretion.
          </li>
        </ul>
        <p>
          <strong>FOR ADDITIONAL INFORMATION,</strong> please see our{" "}
          <Link to="/wrap-fee-brochure-adv-part-2a">Form ADV Part 2A</Link> wrap
          fee brochure, especially Items 4, 5, 6, and 8.
        </p>
        <div className="bg-light">
          <h3 className="text-underline font-size-base">
            QUESTIONS TO ASK US:
          </h3>
          <ul>
            <li>
              Given my financial situation, should I choose an investment
              advisory service? Why or why not?
            </li>
            <li>How will you choose investments to recommend to me?</li>
            <li>
              What is your relevant experience, including your licenses,
              education, and other qualifications? What do these qualifications
              mean?
            </li>
          </ul>
          <p>
            You can find our responses on our website:{" "}
            <a href="/faq/">https://antmoneyadvisors.com/faq</a>
          </p>
        </div>
        <h2 className="text-center text-underline font-size-base">
          FEES, COSTS, CONFLICTS, AND STANDARD OF CONDUCT
        </h2>
        <h3 className="font-size-base">WHAT FEES WILL I PAY?</h3>
        <p>Fees and costs affect the value of your account over time.</p>
        <ul>
          <li>
            The amount you pay to our firm does not vary based on the type of
            investments we select on your behalf.
          </li>
          <li>
            There is no additional cost for users and participants of ATM.com to
            receive the advisory and brokerage services in the program.
            Therefore, it will generally cost you less than purchasing the
            investment management and brokerage services that are bundled in the
            program from other providers separately.
          </li>
          <li>
            You do not pay a transaction fee when we buy and sell an investment
            for you or a custody fee to the broker-dealer that holds your
            assets.
          </li>
          <li>
            The broker-dealer that provides brokerage accounts and services in
            the program may charge additional fees for certain extraordinary
            transactions that are not part of the normal course of the program.
          </li>
        </ul>
        <p className="font-italic">
          <strong>FOR ADDITIONAL INFORMATION,</strong>
          <u>
            you will pay fees and costs whether you make or lose money on your
            investments.
          </u>{" "}
          Fees and costs will reduce any amount of money you make on your
          investments over time. Please make sure you understand what fees and
          costs you are paying.{" "}
          <u>
            Please refer to the fee schedule in our
            <Link to="/wrap-fee-brochure-adv-part-2a/">Form ADV Part 2A</Link>
            wrap fee program brochure
          </u>
        </p>
        <div className="bg-light">
          <h3 className="text-underline font-size-base">
            QUESTIONS TO ASK US:
          </h3>
          <ul>
            <li>
              Help me understand how these fees and costs might affect my
              investments. If I give you $10,000 to invest, how much will go to
              fees and costs, and how much will be invested for me?
            </li>
          </ul>
          <p>
            You can find our responses on our website:{" "}
            <a href="/faq/">https://antmoneyadvisors.com/faq</a>
          </p>
        </div>
        <h3 className="font-size-base">
          WHAT ARE YOUR LEGAL OBLIGATIONS TO ME WHEN ACTING AS MY INVESTMENT
          ADVISER? HOW ELSE DOES YOUR FIRM MAKE MONEY AND WHAT CONFLICTS OF
          INTEREST DO YOU HAVE?
        </h3>
        <p>
          We are held to a fiduciary standard that covers our entire investment
          advisory relationship with you including monitoring your portfolio,
          investment strategy, and investments on an ongoing basis, among other
          requirements.
        </p>
        <p>
          When we act as your investment adviser, we have to act in your best
          interest and not put our interest ahead of yours. At the same time,
          the way we make money creates some conflicts with your interests. Here
          are some examples to help you understand what this means.
        </p>
        <ul>
          <li>
            We receive a portion of the revenue from our affiliate, ATM.com,
            after paying the custodian and other expenses. This creates
            incentives for us to encourage you to invest continuously using our
            affiliate’s mobile app as long as possible. These incentives might
            conflict with your interests in cases where fees and costs were less
            if you or your relatives invested for you without paying the
            subscription fee for our affiliate’s app.
          </li>
        </ul>
        <p>
          <strong>FOR ADDITIONAL INFORMATION,</strong> please see our{" "}
          <Link to="/wrap-fee-brochure-adv-part-2a/">Form ADV Part 2A</Link>{" "}
          wrap fee brochure Items 4, 5, and 10 and any our{" "}
          <Link to="/brochure-supplement-adv-part-2b/">Form ADV Part 2B</Link>{" "}
          brochure supplement.
        </p>
        <div className="bg-light">
          <h3 className="text-underline font-size-base">
            QUESTIONS TO ASK US:
          </h3>
          <ul>
            <li>
              How might your conflicts of interest affect me, and how will you
              address them?
            </li>
          </ul>
          <p>
            You can find our responses on our website:{" "}
            <a href="/faq/">https://antmoneyadvisors.com/faq</a>
          </p>
        </div>
        <h3 className="font-size-base">
          HOW DO YOUR FINANCIAL PROFESSIONALS MAKE MONEY?
        </h3>
        <ul>
          <li>
            We do not provide one-on-one investment advice – whether by
            telephone, email, in person, or otherwise. Rather, we provide our
            services through our website and/or app(s),
          </li>
        </ul>
        <h2 className="text-center text-underline font-size-base">
          DISCIPLINARY HISTORY
        </h2>
        <h3 className="font-size-base">
          DO YOU OR YOUR FINANCIAL PROFESSIONALS HAVE A LEGAL OR DISCIPLINARY
          HISTORY?
        </h3>
        <p>
          <strong>NO.</strong> Please visit{" "}
          <a href="https://www.investor.gov/CRS">Investor.gov/CRS</a> for a free
          search tool to research us and our financial professionals.
        </p>
        <div className="bg-light">
          <h3 className="text-underline font-size-base">
            QUESTIONS TO ASK US:
          </h3>
          <ul>
            <li>
              As a financial professional, do you have any disciplinary history?
              For what type of conduct?
            </li>
          </ul>
          <p>
            You can find our responses on our website:{" "}
            <a href="/faq/">https://antmoneyadvisors.com/faq</a>
          </p>
        </div>
        <h2 className="text-center text-underline font-size-base">
          ADDITIONAL INFORMATION
        </h2>
        <h3 className="font-size-base">
          HOW DO I GET ADDITIONAL INFORMATION ABOUT YOUR FIRM AND SERVICES?
        </h3>
        <p>
          <strong>FOR ADDITIONAL INFORMATION ABOUT OUR SERVICES,</strong> see
          our <Link to="/wrap-fee-brochure-adv-part-2a/">Form ADV Part 2A</Link>{" "}
          wrap fee brochure on{" "}
          <a href="https://www.investor.gov/CRS">Investor.gov/CRS</a>,{" "}
          <a href="https://www.adviserinfo.sec.gov/IAPD/">
            www.adviserinfo.sec.gov/IAPD
          </a>
          , and our{" "}
          <Link to="/brochure-supplement-adv-part-2b/">Form ADV Part 2B</Link>{" "}
          brochure supplement.
        </p>
        <p>
          Call us at (949) 629-4210 or email us at{" "}
          <a href="mailto:support@antmoneyadvisors.com">
            support@antmoneyadvisors.com
          </a>{" "}
          to request up-to-date information and request a copy of the
          relationship summary.
        </p>
        <div className="bg-light">
          <h3 className="text-underline font-size-base">
            QUESTIONS TO ASK US:
          </h3>
          <ul>
            <li>Who is my primary contact person?</li>
            <li>
              Is he or she a representative of an investment adviser or a
              broker-dealer?
            </li>
            <li>
              Who can I talk to if I have concerns about how this person is
              treating me?
            </li>
          </ul>
          <p>
            You can find our responses on our website:{" "}
            <a href="/faq/">https://antmoneyadvisors.com/faq</a>
          </p>
        </div>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Form CRS document.
        </p>
      </Container>
    </Layout>
  )
}

export default FormCrsPage
